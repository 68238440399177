import { chipStyles, groupStyles, labelStyles } from "../BranchList";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Chip from '@mui/material/Chip';

export const getViewData = (storedBranchId) => {
      if (!storedBranchId) return;

      const parsedBranchId = JSON.parse(storedBranchId);

      if (parsedBranchId === "All") {
            return { label: "All Locations", value: "All" };
      } else {
            return {
                  label: (
                        <div style={groupStyles}>
                              <span style={labelStyles}>{parsedBranchId?.label}</span>
                              <span style={chipStyles}>
                                    {parsedBranchId?.is_active ? (
                                          <Chip
                                                icon={<FiberManualRecordIcon style={{ color: "#12B76A", fontSize: 12 }} />}
                                                label={"ACTIVE"}
                                                style={{
                                                      backgroundColor: '#ECFDF3',
                                                      color: "#027A48",
                                                      height: 24,
                                                }}
                                          />
                                    ) : (
                                          <Chip
                                                icon={<FiberManualRecordIcon style={{ color: "#667085", fontSize: 12 }} />}
                                                label={"INACTIVE"}
                                                style={{
                                                      backgroundColor: "#F2F4F7",
                                                      color: "#344054",
                                                      height: 24,
                                                }}
                                          />
                                    )}
                              </span>
                        </div>
                  ),
                  value: parsedBranchId?.value,
            };
      }
};

;
