import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { WeatherSunny20Regular, WeatherMoon20Regular, PeopleRegular } from '@fluentui/react-icons';
import './MonthlyCalendar.css';
// import { BottomBarTableReservation } from "../../../Dashboard/BottomBar";
import moment from "moment";
import headers from "../../../../../CommonApi/headers";
import { BottomBarTableReservation } from "./BottomBar";
import api from "../../../../../CommonApi/axios";
import { LoadingBackdrop } from "../../../../Comman/LoadingBackdrop/LoadingBackdrop";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const currentDate = new Date();

const getDaysInMonth = (month, year) => {
      return new Date(year, month + 1, 0).getDate();
};

const generateCalendar = (month, year) => {
      const daysInMonth = getDaysInMonth(month, year);
      const firstDay = new Date(year, month, 1).getDay();
      const calendar = [];
      let day = 1;

      for (let i = 0; i < 6; i++) {
            let week = [];
            for (let j = 0; j < 7; j++) {
                  if (i === 0 && j < firstDay) {
                        // Add null for days before the start of the month
                        week.push(null);
                  } else if (day > daysInMonth) {
                        // Add null for days after the end of the month
                        week.push(null);
                  } else {
                        week.push(day);
                        day++;
                  }
            }
            calendar.push(week);
      }
      return calendar;
};

const MonthlyCalendar = ({ SelectedDateForMonth, setSelectedDateForMonth,
      setSelectedDate, setSelected, branchDatils, handleApplyFilters }) => {
      // const [selectedDate, setSelectedDate] = useState(new Date());
      const pathname = window.location.pathname;
      const pathnamesplits = pathname.split("/");
      const url23 = pathnamesplits[2];
      const [calendarData, setCalendarData] = useState([]);
      const [loading, setloading] = useState(false);
      const [orderId, setorderId] = useState("")

      function isToday(date) {
            const today = date === moment().format("DD-MM-YYYY");
            // // const formattedDate = date.toString().padStart(2, "0");
            // // const isToday1 = formattedDate === today;
            // console.log("moment: ", moment().format("DD-MM-YYYY"));
            // console.log("moment1: ", date);
            // console.log("moment2: ", date === moment().format("DD-MM-YYYY"));
            return today;
      }


      function handleDateClick(date) {
            if (!date) return;
            const result = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
            setSelectedDateForMonth(result);
            setSelectedDate(result);
            setSelected("table");
            const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
            // console.log("table_reservations_filterData", filterData)
            sessionStorage.setItem(`${url23}_filterData`, JSON.stringify({
                  orderId,
                  orderStatus: filterData?.orderStatus,
                  requestType: filterData?.requestType,
                  startDate: result,
                  endDate: result
            }));
            handleApplyFilters({
                  orderId, orderStatus: filterData?.orderStatus,
                  requestType: filterData?.requestType,
                  startDate: result, endDate: result
            });
      }


      useEffect(() => {
            if (!SelectedDateForMonth) return;

            // Ensure SelectedDateForMonth is a valid Date object
            const dateObj = new Date(SelectedDateForMonth);
            if (isNaN(dateObj.getTime())) return; // Invalid date safety check

            setloading(true);

            const fetchBookings = async () => {
                  try {
                        const response = await api.get(
                              `/groupadmin/table_reservation/${branchDatils?.value || "all"}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`, { headers }
                        );

                        // console.log("response", response)

                        const today = new Date(); // Current system date
                        const currentDay = today.getDate(); // Today's day
                        const currentMonth = today.getMonth();
                        const currentYear = today.getFullYear();

                        const updatedData = (response.data?.data || []).map(item => {
                              const [day, month, year] = item.date.split("-").map(Number); // Convert "DD-MM-YYYY"
                              const itemDate = new Date(year, month - 1, day); // Create a Date object

                              return {
                                    ...item,
                                    pastDateDisable:
                                          itemDate.getFullYear() < currentYear || // Previous years
                                          (itemDate.getFullYear() === currentYear && itemDate.getMonth() < currentMonth), // Previous months
                              };
                        });

                        // const filteredData = updatedData.filter((item, index) => !(index < 7 && item.dinner === null));
                        setCalendarData(updatedData);


                        // console.log("Updated Calendar Data:", updatedData);
                  } catch (error) {
                        console.error("Error fetching bookings:", error);
                  } finally {
                        setloading(false);
                  }
            };

            fetchBookings();
      }, [SelectedDateForMonth, branchDatils]);





      return (
            <Box sx={{ width: "100%", padding: 2 }}>
                  <Box sx={{
                        display: "grid", gridTemplateColumns: "repeat(7, 1fr)", gap: 0,
                  }}>
                        {daysOfWeek.map((day) => (
                              <Box key={day} className="week_day" sx={{ textAlign: "center", fontWeight: "bold" }}>
                                    {day}
                              </Box>
                        ))}
                  </Box>
                  <Box
                        sx={{
                              display: "grid",
                              gridTemplateColumns: "repeat(7, 1fr)",
                              gap: 0,
                              border: "1px solid #dddcdc",
                              borderRadius: "8px",
                              overflow: "hidden" // Ensures inner elements do not overflow the rounded border
                        }}
                  >
                        {calendarData?.map((date, index) => {
                              // Check if the cell is in the first or last row/column
                              const totalColumns = 7;
                              const isFirstRow = index < totalColumns;
                              const isLastRow = index >= calendarData.length - totalColumns;
                              const isFirstColumn = index % totalColumns === 0;
                              const isLastColumn = index % totalColumns === totalColumns - 1;

                              return (
                                    <Box
                                          key={index}
                                          onClick={() => {
                                                if (date?.pastDateDisable === false) {
                                                      handleDateClick(date?.date)
                                                }
                                          }}
                                          sx={{
                                                minHeight: date?.dinner === null ? 130 : 80,
                                                cursor: date?.pastDateDisable === false && "pointer",
                                                border: `0.8px solid ${date?.high
                                                      ? "#FFC26D"
                                                      : isToday(date?.date)
                                                            ? "#1570EF"
                                                            : "#dddcdc"
                                                      }`,
                                                padding: 1,
                                                position: "relative",
                                                backgroundColor: date?.high
                                                      ? "#FFFAEB"
                                                      : date?.dinner !== null
                                                            ? (isToday(date?.date) ? "#EFF8FF" : "#fff")
                                                            : "#f7f7f7",
                                                borderTopLeftRadius: isFirstRow && isFirstColumn ? "8px" : 0,
                                                borderTopRightRadius: isFirstRow && isLastColumn ? "8px" : 0,
                                                borderBottomLeftRadius: isLastRow && isFirstColumn ? "8px" : 0,
                                                borderBottomRightRadius: isLastRow && isLastColumn ? "8px" : 0,
                                                overflow: "hidden", // Prevents content from overflowing rounded corners
                                          }}
                                    >
                                          {date?.dinner !== null && (
                                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                      <Typography variant="body2" sx={{
                                                            fontWeight: "bold", fontSize: "16px", color: `${date?.high
                                                                  ? "#F79009"
                                                                  : isToday(date?.date)
                                                                        ? "#1570EF"
                                                                        : "#000000"
                                                                  }`
                                                      }}>
                                                            {moment(date?.date, "DD-MM-YYYY").format("DD")}
                                                      </Typography>
                                                      <Typography className="type_name" variant="body2">#{date?.reservations_count || 0}</Typography>
                                                      
                                                      {/* {renderTableReservationsMonthlyChip("pending")} */}
                                                </Box>
                                          )}

                                          {date?.dinner !== null && (
                                                <Box className="mt-4 mb-2" sx={{ fontSize: "12px", color: "gray", width: "100%" }}>
                                                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px", }}>
                                                                  <WeatherSunny20Regular style={{ color: "#F79009" }} />
                                                                  <Typography className="type_name" variant="body2">Lunch</Typography>
                                                            </Box>
                                                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                  <PeopleRegular style={{ color: '#101828', fontSize: 18 }} />
                                                                  <Typography className="type_name" variant="body2">{date?.lunch || 0}</Typography>
                                                            </Box>
                                                      </Box>

                                                      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "5px" }}>
                                                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                  <WeatherMoon20Regular style={{ color: "#7A5AF8" }} />
                                                                  <Typography variant="body2" className="type_name">Dinner</Typography>
                                                            </Box>
                                                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                  <PeopleRegular style={{ color: '#101828', fontSize: 18 }} />
                                                                  <Typography className="type_name" variant="body2">{date?.dinner || 0}</Typography>
                                                            </Box>
                                                      </Box>
                                                </Box>
                                          )}
                                    </Box>
                              );
                        })}
                  </Box>
                  <br />
                  {!loading  &&
                        <BottomBarTableReservation />
                  }

                  {loading &&
                        <LoadingBackdrop open={true} />
                  }
            </Box>
      );
};

export default MonthlyCalendar;
