import React, { useEffect, useMemo, useRef, useState } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../../CommonApi/axios";
import headers from "../../../../CommonApi/headers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { connect } from "react-redux";
// import { branchfilterAndMapData, getPlaceholder, getSelectOptions } from "../../Comman/BranchList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TableReservation, userPermissionType } from "../../../Comman/UserPermission";
import useWindowHeight from "../../../NewTableCommon/useWindowsPosition";
import useDrawer from "../../../../context/DrawerContext";
import { HeaderStyle } from "../OrderManagement/TableCommonModule";
import ColumPopoverCommon from "../../../NewTableCommon/ColumPopoverCommon";
import { branchfilterAndMapData, chipStyles, getPlaceholder, getSelectOptions, groupStyles, labelStyles } from "../../../Comman/BranchList";
import { tableOptionTableReservationList } from "../../../NewTableCommon/tableOption";
import { getBranchList } from "../../../../Store";
import { selectStyles } from "../../../Comman/CommonFunction/CommonFunction";
import { renderStatusChipTableReservations } from "../../../NewTableCommon/rendderStatucChipDaynamic";
import FilterPopover from "../OrderManagement/FilterPopover";
import moment from "moment";
import { showSuccessNotification } from "../../../Comman/Swal/Swal";
import TableCancel from "./TableCancel";
import RedirectLink from "../../../Dashboard/RedirectLink";
import { PeopleAdd24Regular, CalendarLtr24Regular, Calendar3Day24Regular, DocumentArrowDown24Regular, ChevronLeft20Filled, ChevronRight20Filled } from '@fluentui/react-icons';
import { Box, Button, ButtonGroup, Chip } from "@mui/material";
import "./TabelResDetails.css"
import { handleClickCSVDownload } from "./AddTableReservation/common";
import MonthlyCalendar from "./MonthlyCalendar/MonthlyCalendar";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import "./TabelResDetails.css"
import { getViewData } from "../../../Comman/AddCommonFun/getViewData";

function TableReservationsList({ getBranchList, BranchList_ }) {
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url23 = pathnamesplits[2];

  const windowHeight = useWindowHeight();
  const filterData = JSON.parse(sessionStorage.getItem(`${url23}_filterData`));
  const branchName = JSON.parse(sessionStorage.getItem(`${url23}_branchName`));

  const userPermissionType_ = userPermissionType === "groupadmin"
  // const dashboardURl = sessionStorage.getItem("dashboardURl");


  const columnsDataString = sessionStorage.getItem(`${url23}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);

  const filteredOptions = branchfilterAndMapData(BranchList_);
  const history = useHistory();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState("");
  const [TableReservationList, setTableReservationList] = useState([])
  const prevSorting = useRef([]);
  const [CopyBranchId, setCopyBranchID] = useState("");
  const [menu_name, setmenu_name] = useState("");
  const [menuId, setmenuId] = useState("");
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // State to manage anchor element for popover

  const today = moment().format("YYYY-MM-DD"); // Today's date in 'YYYY-MM-DD' format
  const [selectedDate, setSelectedDate] = useState(today);
  const [SelectedDateForMonth, setSelectedDateForMonth] = useState(new Date());


  const TypeSession = sessionStorage.getItem("TypeSessionTableReservation")

  useEffect(() => {
    if (TypeSession === null) {
      sessionStorage.setItem("TypeSessionTableReservation", "monthly");
      // sessionStorage.setItem(`${url23}_${Type}_branchName` , JSON.stringify(opt));
    }
  }, [TypeSession === null]);

  const storedBranchId = sessionStorage.getItem(`${url23}_branch`)

  // const [branch_id2, setbranch_id2] = useState();
  const [BranchId, setBranchID] = useState(storedBranchId ? JSON.parse(storedBranchId) : []);

  const [ViewData, setViewData] = useState("");

  // console.log("BranchId", BranchId)

  useEffect(() => {
    setViewData(getViewData(storedBranchId));
  }, [storedBranchId]);

  const [selected, setSelected] = useState(sessionStorage.getItem("TypeSessionTableReservation") || "monthly");
  const [TableReservationListCSVfile, setTableReservationListCSVfile] = useState([])


  const [FilterData, SetFilterData] = useState("");

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  // console.log("FilterData" ,FilterData)


  const [showTableCancel, setShowTableCancel] = useState(false);

  const handleCloseTableCancel = () => {
    setShowTableCancel(false);
  };

  const handleShowTableCancel = () => {
    setShowTableCancel(true);
  };


  const storedState = sessionStorage.getItem(`${url23}_paginationState`);

  useEffect(() => {
    if (storedState !== null) {
      setPagination(JSON.parse(storedState));
    } else {
      setPagination({ pageIndex: 0, pageSize: 10 });
    }
  }, [storedState]);


  const getTableReservationList = async () => {
    // console.log("filterData", filterData?.startDate)

    // const decodedValue = decodeURIComponent("By%20Call");
    // console.log(decodedValue); // Output: "By Call"

    setTableReservationList([]);
    setIsLoading(true);
    setIsError(false)
    let url = `/groupadmin/table_reservation/list?page=${pagination.pageIndex}&per_page=${pagination.pageSize}`;
    let data = JSON.parse(sessionStorage.getItem(`${url23}_dineinfilterData`));
    if (BranchId?.value) {
      url += `&branch_id=${BranchId?.value}`;
    }
    if (globalFilter) {
      url += `&search=${globalFilter ?? ''}`;
    }

    if (FilterData?.requestType?.value) {
      // const formattedType = FilterData?.requestType?.value.replace(/\s+/g, "-");
      // const encodedType = encodeURIComponent(formattedType);
      url += `&booking_request_type=${FilterData?.requestType?.value ?? ''}`;
    }
    if (FilterData?.startDate) {
      url += `&start_date=${FilterData?.startDate}`;
    }
    if (FilterData?.endDate) {
      const result2 = moment(FilterData?.endDate).add(1, "days").toDate();
      url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
    }
    if (FilterData?.orderStatus?.value) {
      url += `&status=${FilterData?.orderStatus?.value}`;
    }

    if (sorting?.length > 0 && sorting[0]?.id) {
      switch (sorting[0]?.id) {
        case "branch":
          url += `&sort_field=branch.branch_name_EN`;
          break;
        case "datetime":
          url += `&sort_field=order_date`;
          break;
        // Add additional cases as needed
        default:
          url += `&sort_field=${sorting[0]?.id}`;
          break;
      }
    }

    if (sorting?.length > 0 && sorting[0]?.desc === true) {
      url += `&sort_type=${'-1'}`;
    }
    if (sorting?.length > 0 && sorting[0]?.desc === false) {
      url += `&sort_type=${'1'}`;
    }
    try {
      const res = await api.get(url, { headers });
      setRowCount(res.data.data?.totalReservations || 0);
      setTableReservationList(res.data.data?.filteredReservationsData);
      // console.log("ddd" ,res.data.data)
    } catch (err) {
      if (err) {
        setRowCount(0);
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const getTableReservationListCSVFile = async () => {
    // console.log("filterData", filterData?.startDate)

    setTableReservationListCSVfile([]);
    setIsLoading(true);
    setIsError(false)
    let url = `/groupadmin/table_reservation/exportData?`;
    if (BranchId?.value) {
      url += `&branch_id=${BranchId?.value}`;
    }
    if (globalFilter) {
      url += `&search=${globalFilter ?? ''}`;
    }

    if (FilterData?.requestType?.value) {
      url += `&booking_request_type=${FilterData?.requestType?.value ?? ''}`;
    }
    if (FilterData?.startDate) {
      url += `&start_date=${FilterData?.startDate}`;
    }
    if (FilterData?.endDate) {
      const result2 = moment(FilterData?.endDate).add(1, "days").toDate();
      url += `&end_date=${moment(result2).format("YYYY-MM-DD")}`;
    }
    if (FilterData?.orderStatus?.value) {
      url += `&status=${FilterData?.orderStatus?.value}`;
    }

    if (sorting?.length > 0 && sorting[0]?.id) {
      switch (sorting[0]?.id) {
        case "branch":
          url += `&sort_field=branch.branch_name_EN`;
          break;
        case "datetime":
          url += `&sort_field=order_date`;
          break;
        // Add additional cases as needed
        default:
          url += `&sort_field=${sorting[0]?.id}`;
          break;
      }
    }

    if (sorting?.length > 0 && sorting[0]?.desc === true) {
      url += `&sort_type=${'-1'}`;
    }
    if (sorting?.length > 0 && sorting[0]?.desc === false) {
      url += `&sort_type=${'1'}`;
    }
    try {
      const res = await api.get(url, { headers });
      // setRowCount(res.data.data?.totalReservations || 0);
      setTableReservationListCSVfile(res.data.data?.filteredReservationsData);
      // console.log("ddd" ,res.data.data)
    } catch (err) {
      if (err) {
        // setRowCount(0);
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sortingChanged = !arraysAreEqual(prevSorting.current, sorting);
  prevSorting.current = sorting;

  useEffect(() => {
    if (pagination.pageIndex, pagination.pageSize) {
      getTableReservationList();
    }
  }, [pagination.pageIndex, pagination.pageSize, globalFilter ?? '',
  BranchId?.value, FilterData?.startDate, FilterData?.endDate, FilterData?.orderStatus,
    sortingChanged, FilterData?.requestType, selected]);

  useEffect(() => {
    getTableReservationListCSVFile();
  }, [globalFilter ?? '',
  BranchId?.value, FilterData?.startDate, FilterData?.endDate, FilterData?.orderStatus,
    sortingChanged, FilterData?.requestType, selected]);


  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  const prevPaginationRef = useRef();

  useEffect(() => {
    if (prevPaginationRef.current && prevPaginationRef.current !== pagination) {
      sessionStorage.setItem(`${url23}_paginationState`, JSON.stringify(pagination));
    }
    prevPaginationRef.current = pagination;
  }, [pagination]);



  useEffect(() => {
    getBranchList();
  }, [])


  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url23}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  const [DataTable, setDataTable] = useState("")
  function handleSubmitEdit(data, status) {

    const handleApiCall = (status, successMessage) => {
      const item = { reservation_status: status };

      api
        .post(`/groupadmin/table_reservation/${data?._id}`, item, { headers })
        .then(() => {
          showSuccessNotification(successMessage);
          getTableReservationList();
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err?.response?.data?.error?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    };

    switch (status) {
      case "accepted":
        handleApiCall("accepted", "Table reservation accepted successfully.");
        break;

      case "cancelled":
        handleShowTableCancel();
        setDataTable(data);
        break;

      case "Send Email":
        handleApiCall(data?.status, "Email sent successfully.");
        break;

      // case "Reject Email":
      //   handleApiCall("cancelled", "Email sent successfully.");
      //   break;

      default:
        console.error("Invalid status:", status);
    }
  }



  const Columns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'booking_id',
        header: 'Booking ID',
        size: 50,
        Cell: ({ row }) => {
          const hasViewPermission = userPermissionType_ || TableReservation?.access?.includes("view");
          return hasViewPermission ? (
            <Link
              to={{
                pathname: `/group_admin/table_reservations/table_reservations_details/${row?.original?._id}`,
                // state: { menuId: row?.original?._id, branchID: row?.original?.branch?._id },
              }}
            >
              <span style={HeaderStyle?.uniqueCodeHeader}>
                {row?.original?.booking_id}
              </span>
            </Link>
          ) : (
            <span style={HeaderStyle?.uniqueCodeHeader}>
              {row?.original?.menu_name}
            </span>
          );
        },
      }
      ,

      {
        accessorKey: 'branchname',
        header: 'Branch',
        size: 180,
        Cell: ({ row }) => row?.original?.branch?.branch_name_EN,
        sortingFn: (rowA, rowB) => {
          const dateA = rowA?.original?.branch?.branch_name_EN;
          const dateB = rowB?.original?.branch?.branch_name_EN;

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        }
      },
      {
        accessorKey: 'booking_type',
        header: 'Booking Type',
        size: 70,
        Cell: ({ row }) => (
          <span style={{ textTransform: "capitalize" }}>
            {row?.original?.booking_type || "---"}
          </span>
        )
      },
      {
        accessorKey: 'booking_date',
        header: 'Booking Date',
        size: 100,
        Cell: ({ row }) => moment(row?.original?.booking_date).format("DD-MM-YYYY")
      },
      {
        accessorKey: 'booking_time',
        header: 'Booking Slot',
        size: 100,
        Cell: ({ row }) => (row?.original?.booking_time)
      },
      {
        accessorKey: 'booking_request_type',
        header: 'Request Type',
        size: 100,
        Cell: ({ row }) => (
          <span style={{ textTransform: "capitalize" }}>
            {row?.original?.booking_request_type === "By-Call" ? "By Call" : row?.original?.booking_request_type || "---"}
          </span>
        )
      },
      {
        accessorKey: 'table_number',
        header: 'Table No',
        size: 100,
        Cell: ({ row }) => (row?.original?.table_number || "---")
      },
      {
        accessorKey: 'no_of_guests',
        header: 'Total Guests',
        size: 100,
        Cell: ({ row }) => (row?.original?.no_of_guests)
      },
      {
        accessorKey: 'name',
        header: 'Customer Details',
        size: 270,
        Cell: ({ row }) => (<>
          <div>
            {row?.original?.name}
          </div>
          <div>
            {row?.original?.phone_number}
          </div>
          <div>
            {row?.original?.email}
          </div>
        </>),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({ row }) => renderStatusChipTableReservations(row?.original?.status),
      },
      {
        accessorKey: 'special_requirements',
        header: 'Special Requirements',
        size: 200,
        Cell: ({ row }) => <span style={{ color: "#9333ea" }}>{row?.original?.special_requirements || "---"}</span>
      },
      {
        accessorKey: 'message',
        header: 'Message',
        size: 250,
        Cell: ({ row }) => <span style={{ color: "#9333ea" }}>{row?.original?.message || "---"}</span>
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);

  const table = useMaterialReactTable({
    columns: Columns,
    data: TableReservationList,
    ...tableOptionTableReservationList({
      getTableReservationList,
      url23, handleSubmitEdit, handleShowTableCancel,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      isError,
      isRefetching,
      columnFilters,
      globalFilter,
      sorting,
      setColumnFilters,
      setGlobalFilter,
      setPagination,
      setSorting,
      rowCount,
      isLoading,
      pagination,
      windowHeight,
    }),
  });



  const handleClick = (button) => {
    setSelected(button);
    sessionStorage.setItem("TypeSessionTableReservation", button);
  };


  const nextMonth = () => {
    setSelectedDateForMonth((prevDate) => {
      // Ensure prevDate is treated as a Date object
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() + 1, 1); // Set to next month's first day
      return newDate;
    });
  };

  const prevMonth = () => {
    setSelectedDateForMonth((prevDate) => {
      // Ensure prevDate is treated as a Date object
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() - 1, 1); // Set to previous month's first day
      return newDate;
    });
  };


  // const isPrevMonthDisabled = () => {
  //       return moment(SelectedDateForMonth).isBefore(moment(), "month");
  // };

  const isPrevMonthDisabled = () => {
    if (!(SelectedDateForMonth instanceof Date)) return true; // Disable if it's invalid
    const prevMonthDate = new Date(SelectedDateForMonth.getFullYear(), SelectedDateForMonth.getMonth() - 1, 1);
    const currentMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    return prevMonthDate < currentMonthStart;
  };

  // console.log("selected" ,selected)


  const [show, setShow] = useState(false);

  const handleClose = () => {
    setmenu_name("");
    setBranchID("");
    setmenuId("");
    setShow(false)
  };

  const handleShow = () => setShow(true);

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


  return (
    <React.Fragment>
      <ToastContainer
        toastStyle={{
          border: "1px solid black",
          fontSize: "17px",
        }}
      />
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                {/* <div style={{height : windowHeight}}  id="kt_content_container" className="container-xxl"> */}
                <div style={{ height: windowHeight }} id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={mainClassCustome.join(" ")}>
                    <div className="row ">
                      <div className="col-md-7">
                        <div
                          className="d-flex justify-content-start"
                          data-kt-docs-table-toolbar="base"
                        >
                          <h1 style={{ marginTop: "9px" }} className="anchor fw-bolder mb-5">
                            Table Reservations
                          </h1>
                          <div style={{ width: '2px', height: '33px', backgroundColor: '#D0D5DD' }} className="mx-4 mt-2" />

                          <Select
                            className="search-line mx-3"
                            value={ViewData}
                            placeholder={getPlaceholder(filteredOptions)}
                            options={getSelectOptions(filteredOptions)}

                            onChange={(opt) => {
                              setBranchID(opt?.value);
                              // console.log("ss" ,opt?.value)
                              sessionStorage.setItem(`${url23}_branch`, JSON.stringify(opt?.value));
                            }}
                            // onChange={(opt) => {
                            //   if (opt.label === "All Locations") {
                            //     // console.log("opt", opt.label === "All Locations")
                            //     getTableReservationList();
                            //     setBranchID("");
                            //     sessionStorage.removeItem(`${url23}_branchName`)
                            //   } else {
                            //     setBranchID(opt);
                            //     sessionStorage.setItem(`${url23}_branchName`, JSON.stringify(opt));
                            //   }
                            // }}
                            styles={selectStyles}
                          />


                          {selected === "monthly" && <h4 className="title__1" style={{ display: "flex", alignItems: "center" }}>
                            <ChevronLeft20Filled
                              className={`navigation-icon me-2 ${isPrevMonthDisabled() ? "disabled" : ""}`}
                              onClick={!isPrevMonthDisabled() ? prevMonth : null}
                            />
                            <span className="month-range me-2">{moment(SelectedDateForMonth).format('MMMM YYYY')}</span>
                            <ChevronRight20Filled className="navigation-icon" onClick={nextMonth} />
                          </h4>}

                        </div>
                      </div>
                      <div className="col-md-5 text-end">
                        <div
                          className="d-flex justify-content-end"
                          data-kt-docs-table-toolbar="base"
                        >



                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div className="me-3 button_group_view" style={{ display: "flex", height: 44 }}>
                              <button
                                style={{
                                  background: selected === "table" ? "#fff" : "rgb(237 237 237)",
                                  border: selected === "table" ? "1px solid #dddcdc" : "0px",
                                  borderRadius: "10px",
                                  width: "55px",
                                  cursor: "pointer",
                                  // padding: "2px",
                                  margin: selected === "table" && "3px 0px 3px 3px"
                                }}
                                onClick={() => handleClick("table")}
                              >
                                <Calendar3Day24Regular className="icon_view" />
                              </button>

                              <button
                                style={{
                                  background: selected === "monthly" ? "#fff" : "rgb(237 237 237)",
                                  border: selected === "monthly" ? "1px solid #dddcdc" : "0px",
                                  borderRadius: "10px",
                                  width: "55px",
                                  cursor: "pointer",
                                  margin: selected === "monthly" && "3px 2px 3px 0px"
                                  // padding: "2px",
                                }}
                                onClick={() => {
                                  handleClick("monthly");
                                  // setSelectedDateForMonth(new Date());
                                }}
                              >
                                <CalendarLtr24Regular className="icon_view" />
                              </button>
                            </div>
                          </div>



                          {selected === "table" &&
                            <button
                              style={{ height: "43px" }}
                              className="btn btn-danger me-4"
                              disabled={(TableReservationListCSVfile?.length > 0 && selected === "table") ? false : true}
                              onClick={() => handleClickCSVDownload(TableReservationListCSVfile)}
                            >
                              <DocumentArrowDown24Regular className="me-2" /> Export Data
                            </button>}

                          {/* } */}



                          {/* {console.log("TableReservation" ,TableReservation)} */}

                          {(userPermissionType_ || TableReservation?.access?.includes("create")) &&

                            // <Button  style={{
                            //   textTransform :"none" ,background :"#F25042",color : "#fff",
                            //   textSize : "14px" ,fontWeight :700 ,border : "0px"
                            //   }} variant="contained" startIcon={<PeopleAdd24Regular />}>
                            //   
                            // </Button>




                            <button
                              style={{ height: "42px", border: "0px" }}
                              className="btn btn-primary"
                              onClick={() =>
                                history.push("/group_admin/table_reservations/add_reservation")
                              }
                            >
                              <PeopleAdd24Regular className="me-2" /> New Reservation
                            </button>

                          }

                          {/* {(userPermissionType_ || MenuPermissions?.access?.includes("create")) &&
                            <Addnewdetails
                              url={"/group_admin/menu_management/add_new_menu"}
                              Name="Add New Menu" />
                          } */}
                        </div>
                      </div>
                    </div>

                    <hr style={{ opacity: 0.07, marginTop: 7 }} />
                    <div className="mt-5">
                      {selected === "table" ?
                        <MaterialReactTable
                          table={table}
                        /> : <MonthlyCalendar
                          branchDatils={BranchId}
                          SelectedDateForMonth={SelectedDateForMonth}
                          setSelectedDateForMonth={setSelectedDateForMonth}
                          setSelectedDate={setSelectedDate}
                          setSelected={setSelected}
                          handleApplyFilters={handleApplyFilters}
                        />
                      }
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div >

      <ColumPopoverCommon
        anchorEl_={anchorEl_}
        handleClickColum_={handleClickColum_}
        onColumnDataChange={onColumnDataChange}
        handleCloseColum_={handleCloseColum_}
        url23={url23}
      />

      <FilterPopover
        selected={selected}
        open={isFilterPopoverOpen}
        anchorEl={filterAnchorEl}
        Type={""}
        handleClose={handleFilterClose}
        handleFilter={handleApplyFilters}
      />


      <TableCancel
        showTableCancel={showTableCancel}
        handleCloseTableCancel={handleCloseTableCancel}
        getTableReservationList={() => getTableReservationList()}
        DataTable={DataTable}
      />


    </React.Fragment >
  );
}

const mapStateToProps = (state) => ({
  BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: () => dispatch(getBranchList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableReservationsList);

