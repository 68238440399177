// dataUtils.js

import { userPermissionType } from "./UserPermission";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Chip from '@mui/material/Chip';



export const branchfilterAndMapData = (responseData) => {

  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const urlDaynamic = pathnamesplits[2];
  let filterData;

  switch (urlDaynamic) {
    case "dashboard":
      filterData = responseData;
      break;
    case "report":
      filterData = responseData;
      break;
    case "table_reservations":
      filterData = responseData;
      break;
    default:
      // Filter branches that are active for other URLs
      filterData = responseData?.filter(branch => branch?.is_active);
      break;
  }

  // const filterData = responseData?.filter(branch => branch?.is_active);
  const options = filterData?.map((item) => ({
    label: item.branch_name_EN,
    value: item._id,
    currency: item?.currency,
    is_active: item?.is_active
  }));
  return options;
};

// export const getSelectOptions = (filteredOptions) => {
//   if (userPermissionType === "groupadmin") {
//     return [{ label: "All Locations", value: "All" }, ...filteredOptions];
//   } else if (filteredOptions.length >= 2) {
//     return [{ label: "All Locations", value: "All" }, ...filteredOptions];
//   } else {
//     return [...filteredOptions];
//   }
// };


export const groupStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden', // Ensures no overflow
  whiteSpace: 'nowrap', // Prevents label from wrapping to the next line
  textOverflow: 'ellipsis', // Displays ellipsis (...) when text overflows
};

export const labelStyles = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  flex: '1', // Ensures the label takes up the available space
};

export const chipStyles = {
  marginLeft: '10px', // Add space between the label and the chip
};

export const getSelectOptions = (filteredOptions) => {

  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const urlDaynamic = pathnamesplits[2];
  let filterData;

  // Map through filteredOptions to construct each label
  const optionsWithCustomLabel = filteredOptions?.map((main) => ({
    label: (
      <div style={groupStyles}>
        <span style={labelStyles}>{main?.label}</span>
        <span style={chipStyles}>
          {main?.is_active ? (
            <Chip
              icon={<FiberManualRecordIcon style={{ color: "#12B76A", fontSize: 12 }} />}
              label={"ACTIVE"}
              style={{
                backgroundColor: '#ECFDF3',
                color: "#027A48",
                height: 24,
              }}
            />
          ) : (
            <Chip
              icon={<FiberManualRecordIcon style={{ color: "#667085", fontSize: 12 }} />}
              label={"INACTIVE"}
              style={{
                backgroundColor: "#F2F4F7",
                color: "#344054",
                height: 24,
              }}
            />
          )}
        </span>
      </div>
    ),
    value: main,
  }));

  switch (urlDaynamic) {
    case "dashboard":
      filterData = optionsWithCustomLabel;
      break;
    case "report":
      filterData = optionsWithCustomLabel;
      break;
    case "table_reservations":
      filterData = optionsWithCustomLabel;
      break;
    default:
      filterData = filteredOptions;
      break;
  }

  // Add "All Locations" conditionally based on the rules
  const allLocationsOption = { label: "All Locations", value: "All" };

  if (userPermissionType === "groupadmin" || filterData.length >= 2) {
    return [allLocationsOption, ...filterData];
  } else {
    return filterData;
  }
};

export const getPlaceholder = (filteredOptions) => {
  if (userPermissionType === "groupadmin" || filteredOptions.length >= 2) {
    return "All Locations";
  } else {
    return "Select Location";
  }
};


export const ALLLOCATIONSTYLE = {
  control: (provided) => ({
    ...provided,
    borderRadius: "8px",
    height: "30px",
    marginTop: "5px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};




