import React, { useEffect, useState } from "react";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import Select from "react-select";
import { showSuccessTableBook } from "../../../../Comman/Swal/Swal";
import { Form } from "react-bootstrap";
import { colourStylesHeader, SelectTypeOptions, specialRequirementsOptions } from "./common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./AddTableReservation.css"
import GoBackButton from "../../../../Comman/GoBackButton/GoBackButton";
import useDrawer from "../../../../../context/DrawerContext";
import moment from "moment";
import { connect } from "react-redux";
import { getBranchList } from "../../../../../Store";
import api from "../../../../../CommonApi/axios";
import headers from "../../../../../CommonApi/headers";
import { validateEmail } from "../../../../Comman/AddCommonFun/validation";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import { showToastCommon } from "../../../../Comman/Toastify/Toastify";
import { UserPermissionAllData } from "../../../../Comman/UserPermission";

function AddTableReservation({ getBranchList, BranchList_ }) {

      const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();
      // const RESGroup = UserPermissionAllData;
      useEffect(() => {
            window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
            getBranchList();
      }, []);

      // console.log("RESGroup" ,UserPermissionAllData)

      const filteredOptions = BranchList_?.filter(branch => branch?.is_active)?.map((item) => ({
            label: item.branch_name_EN,
            value: item._id,
            currency: item?.currency,
            is_active: item?.is_active
      }));

      const [loading, setloading] = useState(false)
      const [NumberGuest1, setNumberGuest1] = useState("");
      const [SpecialRequirement, setSpecialRequirement] = useState("");
      const [value, setValue] = useState(new Date().toISOString().split("T")[0])
      const [BranchID, setBranchID] = useState("");
      // const [BranchData, setBranchData] = useState("");
      const [branch_mails, setbranch_mails] = useState("");
      const [selectMethod, setselectMethod] = useState("")
      const [lunchSlots, setLunchSlots] = useState([]);
      const [dinnerSlots, setDinnerSlots] = useState([]);
      const [userTime, setUserTime] = useState(""); // State to track selected time
      const [guestsMin_Max, setguestsMin_Max] = useState([]);
      const [error, seterror] = useState(null)
      const [formData, setFormData] = useState({
            name: "",
            email: "",
            phone: "",
            message: "",
      });
      const [MethodType, setMethodType] = useState("");

      // console.log("formData" ,formData)

      let history = useHistory();

      const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      function handalSubmit(event) {
            event.preventDefault();
            seterror(null);

            if (BranchID === "") {
                  seterror("Please select location.");
                  return;

            }
            else if (NumberGuest1 === "") {
                  seterror("Please enter number of guests.");
                  return;
            }
            else if (selectMethod === "") {
                  seterror("Please select reservation request type.");
                  return;
            } else if (value === "") {
                  seterror("Please select date.");
                  return;
            }
            else if (MethodType === "") {
                  seterror("Please select type.");
                  return;
            }

            else if (userTime === "") {
                  seterror("Please select time slot.");
                  return;
            }

            // else if (selectMethod === "") {
            //       seterror(`Please select a valid slot to proceed.`);
            //       if (lunchSlots?.length > 0) {
            //             seterror(`Please select lunch.`);
            //       } else if (dinnerSlots?.length > 0) {
            //             seterror(`Please select dinner.`);
            //       }
            // } 
            // else if (userTime === null) {
            //       seterror(`Please select ${selectMethod.toLowerCase()} timing.`);
            //       return;
            // } 
            else if (formData?.name === "") {
                  seterror("Please enter name.");
                  return;
            }
            else if (formData?.email === "") {
                  seterror("Please enter email.");
                  return;
            } else if (!validateEmail(formData?.email)) {
                  seterror("Enter email is invalid.");
                  return;
            } else if (formData?.phone === "") {
                  seterror("Please enter phone number.");
                  return;
            }
            // else if (Terms_and_conditions === false) {
            //       seterror("Please accept terms and conditions.");
            //       return;
            // }
            else {



                  let item = {
                        // restaurant_id: UserPermissionAllData?.uid,
                        branch_id: BranchID?.value,
                        branch_name: BranchID?.label,
                        no_of_guests: Number(NumberGuest1),
                        booking_date: moment(value).format("YYYY-MM-DD"),
                        booking_type: MethodType?.value,
                        booking_time: userTime?.value,
                        name: formData?.name,
                        email: formData?.email,
                        phone_number: formData?.phone,
                        message: formData?.message,
                        special_requirements: SpecialRequirement?.value,
                        booking_request_type: selectMethod.replace(/\s+/g, "-"),
                        branch_email : branch_mails
                  }

                  // console.log("sss" ,BranchData?.main?.table_reservation_time?.branch_mails)

                  // Filter out empty, undefined, or null fields
                  let filteredItem = Object.keys(item).reduce((acc, key) => {
                        if (item[key] !== undefined && item[key] !== null && item[key] !== "") {
                              acc[key] = item[key];
                        }
                        return acc;
                  }, {});

                  // console.log("Filtered Item:", filteredItem );
                  // return

                  api
                        .post(`/groupadmin/table_reservation/add`, filteredItem, { headers })
                        .then((response) => {
                              showSuccessTableBook("Reservation added successfully")
                              // console.log("response.data", response.data);
                              // setFormData("");
                              setTimeout(() => {
                                    window.scrollTo({ top: 0, behavior: "smooth" });
                                    // window.location.reload(true);
                              }, 2000);
                              seterror(null)
                              // setBranchData("");
                              setBranchID("");
                              setselectMethod("")
                              setUserTime("");
                              setDinnerSlots([]);
                              setLunchSlots([]);
                              setNumberGuest1("");
                              // setValue(new Date());
                              // handleCalendarChange(new Date())
                              setSpecialRequirement("");
                              fetchBranchList();
                              history.push("/group_admin/table_reservations")
                        })
                        .catch((err) => {
                              // setloader(false);
                              // setcheckOutEroor(err?.response?.data?.error?.message);
                        });
            }
      }

      const filterAndGenerateSlots = (BranchData) => {

            const dynamicTargetDate = moment(value).format("DD-MM-YYYY");
            // Format the target date dynamically
            const formattedTargetDate = moment(dynamicTargetDate, "DD-MM-YYYY").format("YYYY-MM-DD");
            const dayOfWeek = moment(formattedTargetDate).format("dddd").toLowerCase(); // Get the day name in lowercase

            // Helper function to generate time slots
            const generateTimeSlots = (startTime, endTime, interval) => {
                  const slots = [];
                  const currentMoment = moment(); // Current time
                  const isToday = currentMoment.isSame(moment(formattedTargetDate, "YYYY-MM-DD"), 'day'); // Check if the date is today

                  let currentTime = moment(startTime, "HH:mm");
                  const endTimeMoment = moment(endTime, "HH:mm");

                  while (currentTime <= endTimeMoment) {
                        if (!isToday || currentTime.isAfter(currentMoment)) {
                              // Add slot only if it's not today or the slot is after the current time
                              slots.push(currentTime.format("hh:mm A")); // Format as 12-hour time
                              // slots.push(currentTime.format("HH:mm"));
                        }
                        currentTime = currentTime.add(interval, "minutes");
                  }

                  return slots;
            };

            if (BranchData?.slot_management_type === "manual") {
                  // Extract manual_dates from BranchData
                  const manualDates = BranchData?.manual_dates || [];

                  // Filter slots for the given date
                  const matchingSlots = manualDates.filter(slot => slot.date === formattedTargetDate);

                  // Ensure the function always returns an object with lunchSlots and dinnerSlots
                  if (matchingSlots.length === 0) {
                        return { lunchSlots: [], dinnerSlots: [], guestsMin_Max: [] };
                  }
                  // **Filter and sort Lunch slots**
                  const lunchSlotsData = matchingSlots
                        .filter(slot => slot.booking_type === "Lunch") // Filter by "Lunch"
                        .sort((a, b) => moment(a?.start_time, "HH:mm").diff(moment(b?.start_time, "HH:mm")));


                  const dinnerSlotsData = matchingSlots
                        .filter(slot => slot.booking_type === "Dinner") // Filter by "Dinner"
                        .sort((a, b) => moment(a?.start_time, "HH:mm").diff(moment(b?.start_time, "HH:mm"))); // Sort by start time

                  // console.log("lunchSlotsData", lunchSlotsData)

                  // Generate time slots
                  const lunchSlots = lunchSlotsData.flatMap(slot =>
                        generateTimeSlots(slot.start_time, slot.end_time, slot.booking_interval)
                  );

                  const dinnerSlots = dinnerSlotsData.flatMap(slot =>
                        generateTimeSlots(slot.start_time, slot.end_time, slot.booking_interval)
                  );

                  return { lunchSlots, dinnerSlots, guestsMin_Max };


            } else if (BranchData?.slot_management_type === "auto") {
                  const daySlots = BranchData?.[dayOfWeek] || [];

                  if (daySlots.length === 0) {
                        return { lunchSlots: [], dinnerSlots: [], guestsMin_Max: [] };
                  }

                  // Dynamically get the correct start and end time keys
                  const startTimeKey = `${dayOfWeek.slice(0, 3)}_start_time`;
                  const endTimeKey = `${dayOfWeek.slice(0, 3)}_end_time`;

                  // **Filter and sort Lunch slots**
                  const lunchSlotsData = daySlots
                        .filter(slot => slot.booking_type === "Lunch") // Filter by "Lunch"
                        .sort((a, b) => moment(a[startTimeKey], "HH:mm").diff(moment(b[startTimeKey], "HH:mm"))); // Sort by start time

                  // **Filter and sort Dinner slots**
                  const dinnerSlotsData = daySlots
                        .filter(slot => slot.booking_type === "Dinner") // Filter by "Dinner"
                        .sort((a, b) => moment(a[startTimeKey], "HH:mm").diff(moment(b[startTimeKey], "HH:mm"))); // Sort by start time

                  // Generate time slots
                  const lunchSlots = lunchSlotsData.flatMap(slot =>
                        generateTimeSlots(slot[startTimeKey], slot[endTimeKey], slot.booking_interval)
                  );

                  const dinnerSlots = dinnerSlotsData.flatMap(slot =>
                        generateTimeSlots(slot[startTimeKey], slot[endTimeKey], slot.booking_interval)
                  );

                  return { lunchSlots, dinnerSlots, guestsMin_Max };

            }

            // Default return if slot_management_type is neither "manual" nor "auto"
            return { lunchSlots: [], dinnerSlots: [], guestsMin_Max: [] };
      };

      useEffect(() => {
            fetchBranchList()
      }, [value, BranchID]);


      function fetchBranchList() {
            // settimeData([])
            api
                  .get(`/groupadmin/settings/table_reservation_time/table_reservation/${BranchID?.value}`, { headers })
                  .then((res) => {
                        const tableData = res.data.data;
                        // settimeData(tableData);
                        console.log("tableData", tableData[0]);


                        setbranch_mails(tableData[0]?.branch_mails || "")
                        if (tableData[0].reservation_allowed === false) {
                              showToastCommon(`Currently, we are not accepting reservations for ${BranchID?.label}`)

                        } else {
                              if (tableData[0]) {
                                    if (tableData[0]?.reservation_allowed === true) {
                                          // const dynamicTargetDate = moment().format("DD-MM-YYYY"); // Example: current date
                                          const { lunchSlots, dinnerSlots, guestsMin_Max } = filterAndGenerateSlots(tableData[0]);
                                          // Update the state with the generated slots
                                          setLunchSlots(lunchSlots);
                                          setDinnerSlots(dinnerSlots);
                                          setguestsMin_Max(guestsMin_Max);
                                    } else {
                                          setLunchSlots([]);
                                          setDinnerSlots([]);
                                          setguestsMin_Max([]);
                                    }

                              }
                        }

                  })
                  .catch((err) => {
                        if (err.response) {
                              // settimeData([]);
                        }
                  });
      }

      // console.log("lun", lunchSlots);
      // console.log("din", dinnerSlots);



      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={mainClassCustome.join(" ")}>
                                                            <div className="d-flex flex-stack mb-2">
                                                                  <div
                                                                        className="d-flex justify-content-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h1 className="anchor fw-bolder" id="custom-form-control">
                                                                              New Reservation
                                                                        </h1>
                                                                  </div>
                                                                  <div className="d-flex flex-stack">
                                                                        <div
                                                                              className="d-flex justify-content-end mx-2"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <GoBackButton />
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <div className="py-5">
                                                                  <div className="card card-docs mb-2">
                                                                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                                                                              <div className="rounded border p-10">
                                                                                    <form onSubmit={handalSubmit}>
                                                                                          <div className="row">


                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="required form-label">Branch</label>
                                                                                                      <Select
                                                                                                            className="search-line"
                                                                                                            placeholder="Select Branch"
                                                                                                            options={filteredOptions}
                                                                                                            styles={colourStylesHeader}
                                                                                                            onChange={(opt) => {
                                                                                                                  setBranchID(opt);
                                                                                                                  setMethodType("");
                                                                                                                  setValue(new Date().toISOString().split("T")[0]);
                                                                                                                  setUserTime("")
                                                                                                                  // Handle special requirements selection
                                                                                                            }}
                                                                                                      />
                                                                                                </div>

                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="required form-label">Number Of Guests</label>
                                                                                                      <input
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            placeholder="Enter Number Of Guests"
                                                                                                            name="guests"
                                                                                                            value={NumberGuest1}
                                                                                                            onChange={(e) => setNumberGuest1(e.target.value)}
                                                                                                      // required
                                                                                                      />
                                                                                                </div>

                                                                                                <div className="col-md-4 mb-7">


                                                                                                      <Form.Group className="mb-3">
                                                                                                            {/* <Form.Label className="add-label">Reservation Request Type</Form.Label> */}
                                                                                                            <label className="required form-label mb-5">Reservation Request Type</label>
                                                                                                            <div>
                                                                                                                  {/* {["By Call", "Walk-in"].map((type) => (
                                                                                                                        <Form.Check
                                                                                                                              key={type}
                                                                                                                              inline
                                                                                                                              type="radio"
                                                                                                                              className="add-radio"
                                                                                                                              label={type}
                                                                                                                              name="reservationType"
                                                                                                                              value={type}  // Set value as 'type' here
                                                                                                                              checked={selectMethod === type}  // Check if the current type is selected
                                                                                                                              onChange={(e) => setselectMethod(e.target.value)}  // Update state when the radio button is selected
                                                                                                                        />
                                                                                                                  ))} */}

                                                                                                                  {["By Call", "Walk-in"].map((type) => (
                                                                                                                        <label key={type} className="add-radio-label">
                                                                                                                              <input
                                                                                                                                    type="radio"
                                                                                                                                    name="reservationType"
                                                                                                                                    value={type}
                                                                                                                                    inline
                                                                                                                                    className="form-check-input"
                                                                                                                                    style={{cursor :"pointer"}}
                                                                                                                                    checked={selectMethod === type}
                                                                                                                                    onChange={(e) => setselectMethod(e.target.value)}
                                                                                                                              // className="d-none" // Hide default radio button styling
                                                                                                                              />
                                                                                                                              <span className="custom-radio  me-20 mx-3">{type}</span>
                                                                                                                              {/* <label className="form-check-label mx-2">
                                                                                                                              
                                                                                                                                    </label> */}


                                                                                                                            
                                                                                                                        </label>
                                                                                                                  ))}

                                                                                                            </div>



                                                                                                      </Form.Group>
                                                                                                </div>

                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="required form-label">Date Selection</label>
                                                                                                      <input
                                                                                                            type="date"
                                                                                                            className="form-control"
                                                                                                            name="date"
                                                                                                            value={value}
                                                                                                            onChange={(e) => {
                                                                                                                  setMethodType("");
                                                                                                                  setValue(e.target.value);
                                                                                                                  setUserTime("")
                                                                                                            }}
                                                                                                            min={new Date().toISOString().split("T")[0]} // Disable past dates
                                                                                                      />
                                                                                                </div>

                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="required form-label">Select Type</label>
                                                                                                      <Select
                                                                                                            className="search-line"
                                                                                                            placeholder="Select Type"
                                                                                                            options={SelectTypeOptions(lunchSlots, dinnerSlots)}
                                                                                                            styles={colourStylesHeader}
                                                                                                            value={MethodType}
                                                                                                            onChange={(opt) => {
                                                                                                                  setMethodType(opt)
                                                                                                                  // Handle special requirements selection
                                                                                                            }}
                                                                                                      />
                                                                                                </div>





                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="required form-label">Time Selection</label>

                                                                                                      <Select
                                                                                                            className="search-line"
                                                                                                            placeholder="Select Time Selection"
                                                                                                            options={MethodType ? MethodType?.value === "lunch" ? lunchSlots?.map((main) => ({
                                                                                                                  label: main,
                                                                                                                  value: main
                                                                                                            })) : dinnerSlots.map((main) => ({
                                                                                                                  label: main,
                                                                                                                  value: main
                                                                                                            })) : []}
                                                                                                            styles={colourStylesHeader}
                                                                                                            value={userTime}
                                                                                                            onChange={(opt) => {
                                                                                                                  setUserTime(opt)
                                                                                                                  // Handle special requirements selection
                                                                                                            }}
                                                                                                      />

                                                                                                </div>

                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="required form-label">Name</label>
                                                                                                      <input
                                                                                                            type="text"
                                                                                                            className="form-control "
                                                                                                            placeholder="Enter Name"
                                                                                                            name="name"
                                                                                                            value={formData.name}
                                                                                                            onChange={handleChange}
                                                                                                      // required
                                                                                                      />
                                                                                                </div>

                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="required form-label">Email</label>
                                                                                                      <input
                                                                                                            type="email"
                                                                                                            className="form-control "
                                                                                                            placeholder="Enter Email"
                                                                                                            name="email"
                                                                                                            value={formData.email}
                                                                                                            onChange={handleChange}
                                                                                                      // required
                                                                                                      />
                                                                                                </div>

                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="required form-label">Phone</label>
                                                                                                      <input
                                                                                                            type="number"
                                                                                                            className="form-control "
                                                                                                            placeholder="Enter Phone Number"
                                                                                                            name="phone"
                                                                                                            value={formData.phone}
                                                                                                            onChange={handleChange}
                                                                                                      // required
                                                                                                      />
                                                                                                </div>

                                                                                                <div className="col-md-4 mb-7">
                                                                                                      <label className="form-label">Special Requirements</label>
                                                                                                      <Select
                                                                                                            className="search-line"
                                                                                                            placeholder="Select Special Requirements"
                                                                                                            options={specialRequirementsOptions}
                                                                                                            styles={colourStylesHeader}
                                                                                                            onChange={(opt) => {
                                                                                                                  setSpecialRequirement(opt)
                                                                                                                  // Handle special requirements selection
                                                                                                            }}
                                                                                                      />
                                                                                                </div>

                                                                                                <div className="mb-7">
                                                                                                      <label className="form-label">Message</label>
                                                                                                      <textarea
                                                                                                            className="form-control "
                                                                                                            placeholder="Enter Message"
                                                                                                            name="message"
                                                                                                            value={formData.message}
                                                                                                            onChange={handleChange}
                                                                                                            rows={3}
                                                                                                      />
                                                                                                </div>

                                                                                          </div>
                                                                                          <ErrorMessage error={error} />
                                                                                          <div className="modal-footer flex-center">
                                                                                                <button type="submit" className="btn btn-primary">
                                                                                                      <span className="indicator-label">Add Reservation</span>
                                                                                                </button>
                                                                                                <button type="button" className="btn btn-danger m-2" onClick={() => history.goBack()}>
                                                                                                      Cancel
                                                                                                </button>
                                                                                          </div>
                                                                                    </form>

                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <GroupAdminUperSide />
                  </div>
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getBranchList: () => dispatch(getBranchList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTableReservation);

