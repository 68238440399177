import React from 'react'
import Brightness1RoundedIcon from '@mui/icons-material/Brightness1Rounded';
import { Col, Row ,Button } from 'react-bootstrap';


const indicators = [
  { label: "High Bookings", bgColor: "#FFFAEB", borderColor: "#F79009" },
  { label: "Today", bgColor: "#EFF8FF", borderColor: "#1570EF" },
  { label: "Other Days", bgColor: "#E5E7EB", borderColor: "#9CA3AF" }
];

export function BottomBarTableReservation() {
  return (
    <div className="d-flex justify-content-start align-items-end mt-4">
      <Row className="text-start">
        <Col lg={12} md={12} xs={12} sm={12} xl={12} className="d-flex align-items-center">
          {indicators.map(({ label, bgColor, borderColor }, index) => (
            <div key={index} className="d-flex align-items-center mx-2">
              <Button
                style={{
                  width: 25, height: 25,
                  backgroundColor: bgColor,
                  border: `2px solid ${borderColor}`,
                  borderRadius: "50%",
                  padding: 0,
                }}
              />
              <label className="form-check-label mx-2 labale_botton">{label}</label>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
}
